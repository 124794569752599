/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
  }
  
  .modal-box {
    background: #1d9466;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    width: 300px;
    border: 5px solid #7fbb93;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin: 0px;
    padding: 0px;
    height: 30px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    background-color: #1d9466 !important;
    color: #fff;
  }
  
  .network-option {
    display: flex;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    border-top: 1px #fff dashed;
    padding: 10px 0 10px 10px;
    transition: background-color 0.3s ease;
  }

  .network-option:last-child {
    margin-top: 0px;
    border-bottom: 1px #fff dashed;
  }

  .network-option:hover, .network-option:focus {
    background-color: #7aa493 !important;
  }
  
  .network-option img {
    margin-right: 10px;
    width: 30px; /* Adjust size as needed */
  }


  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the boxes horizontally */
    gap: 20px; /* Adjust the space between the boxes */
  }
  
  .box {
    margin-top: 20px;
    flex: 0 1 calc(33.333% - 20px); /* 3 boxes per row, accounting for gap */
    align-self: flex-start; /* Align boxes to the top */
    height: 85px; /* Adjust the height as needed */
    text-align: center;
    border: 3px solid #69ad86;
    padding: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    background-color: #50977b;
    cursor: pointer;
    padding-top:10px;
  }
  
  .box img {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
  }

  .box:hover, .box:focus {
    background-color: #64ab8f;
  }
